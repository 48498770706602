









































































































































































































































































































































































































































































































































































































































































































































































































































































import Dinero from 'dinero.js';
import Vue from 'vue';
import getProject from './dependencies/get_project';
import updateAccess from './dependencies/update_access';
import WbDialog from '@/components/dialog/index.vue';
import WbTextField from '@/components/text_field/index.vue';
import WbSelect from '@/components/select/index.vue';
import WbContentWrapper from '@/components/content_wrapper/index.vue';
import createVendorId, { CreateVendorData } from './dependencies/create_vendor_id';
import { PaymentPlanGroup, getPaymentPlanGroup } from './dependencies/get_project_payment_plan_group';
import uploadLogo from './dependencies/upload_logo';
import { getPaymentPlanGroups, PlanGroup } from './dependencies/get_payment_plan_groups';
import { setProjectPaymentPlanGroup } from './dependencies/set_project_payment_plan_group';
import updateDemoStatus from './dependencies/update_demo_status';
import getFeatures from './dependencies/get_features';
import updateFeatures from './dependencies/update_features';
import { ppmSubscriptions } from './dependencies/subscriptions';
import getProducts, { Product } from './dependencies/get_products';
import updateProjectLimits from './dependencies/update_minmax';
import { updateRefundReserve } from './dependencies/update_refund_reserve';
import { getAccountBalance, AccountBalance } from './dependencies/get_account_balance';
import { chargeMerchant } from './dependencies/charge_merchant';
import { setProjectSeats } from './dependencies/set_project_seats';
import Checkouts from './components/checkouts.vue';
import Tabs from './components/tabs.vue';
import Tab from './components/tab.vue';

enum DemoStatus {
  skipped = 'skipped',
  completed = 'completed',
  pending = 'pending',
}

interface Info {
  name: string;
  projectOwner: string;
  projectOwnerEmail: string;
  analytics: boolean;
  lnpl: boolean;
  assetUrl: string;
  vendorIdLnpl: string | null;
  vendorIdPpm: string | null;
  demoStatus: DemoStatus;
  refundReserve: number | null;
  baseMultiplier: number;
  seats: number;
}

interface BankingDetails {
  id: string;
  dateAdded: Date;
  accountHolderName: string;
  accountNumber: string;
  bankId: string;
  branchId: string;
  currency: string;
}

interface PaymentPlan {
  installments: string;
  minScore: string;
  maxScore: string;
  feeInPercentage: string;
  minOrderTotalInCents: string;
  maxOrderTotalInCents: string;
  downPaymentPercentage: string;
}

interface PriceLimits {
  minPriceInCents: number;
  maxPriceInCents: number;
}

interface SubPlan {
  value: string;
  text: string;
}

interface AccountBalanceFormatted extends AccountBalance {
  amount: string;
}

export default Vue.extend({

  components: {
    WbDialog,
    WbTextField,
    WbSelect,
    WbContentWrapper,
    Checkouts,
    Tabs,
    Tab,
  },
  data() {
    return {
      state: '',
      projectId: '',
      project: {
        info: {} as Info,
        paymentPlans: [] as PaymentPlan[],
        bankingDetails: [] as BankingDetails[],
        priceLimits: {
          maxPriceInCents: 0,
          minPriceInCents: 0,
        } as PriceLimits,
      },
      formState: '',
      editProjectInfoDialog: false,
      editProjectInfoForm: {
        lnpl: false,
        analytics: false,
      },
      editPriceLimitDialog: false,
      editPriceLimitDialogState: '',
      editPriceLimitDialogError: '',
      editPriceLimitForm: {
        min: 0,
        max: 0,
      },
      paymentPlanGroups: [] as PlanGroup[],
      projectPaymentPlanGroup: {} as PaymentPlanGroup,
      products: [] as Product[],
      productsTable: {
        itemsPerPage: 25,
        headers: [
          {
            text: 'id', align: 'start', value: 'id', sortable: false,
          },
          { text: 'Name', align: 'start', value: 'name' },
          { text: 'State', value: 'state' },
          { text: 'Price', value: 'priceInCents' },
          { text: 'PPM enabled', value: 'ppm' },
        ],
      },
      openCreateVendorModal: false,
      createVendorForm: {
        vendorPrincipal: {
          firstName: '',
          lastName: '',
          address: '',
          city: '',
          country: '',
          zip: '',
          dob: '',
          personalIdentificationNumber: '',
          email: '',
        },
      } as CreateVendorData,
      createVendorFormError: '',
      openUploadLogoModal: false,
      logo: {} as File,
      logoUrl: '',
      uploadLogoError: '',
      features: [] as { feature: string; enabled: boolean }[],
      demoStatuses: [
        DemoStatus.completed,
        DemoStatus.skipped,
        DemoStatus.pending,
      ],
      seatsOptions: [1, 3, 5, 25, 9999],
      activeFeatures: [] as string[],
      updateProjectFeaturesState: 'loaded',
      updateProjectFeaturesError: '',
      ppmSubscriptions: [] as SubPlan[],
      createPpmSubscriptionState: 'loaded',
      updateDemoStatusState: 'loaded',
      selectedPpmSubscriptionPlan: '',
      existingPpmSubscriptionPlan: '',
      createPpmSubscriptionError: '',
      updateDemoStatusError: '',
      newDemoStatus: '',
      seats: 0,
      setSeatsError: '',
      setSeatsState: 'loaded',
      openUpdateRefundReserveDialog: false,
      updateRefundReserveData: {},
      refundBaseMultiplier: 1,
      accountBalance: [] as AccountBalanceFormatted[],
      canAccountBeCharged: false,
      showChargeMerchantDialog: false,
      chargeMerchantState: '',
      chargeMerchantError: '',
      chargeAmountInCents: '0',
      chargeCurrency: 'USD',
      currencyOptions: [{ value: 'USD', text: 'USD' }],
    };
  },

  async mounted() {
    this.getData();
  },

  methods: {
    async getData() {
      try {
        this.state = 'loading';
        this.projectId = this.$route.params.id;
        const [
          project,
          paymentPlanGroups,
          projectPaymentPlan,
          features,
          subscriptions,
          subscriptionPlan,
          products,
          accountBalanceData,
        ] = await Promise.all([
          getProject(this.projectId),
          getPaymentPlanGroups(),
          getPaymentPlanGroup(this.projectId),
          getFeatures(this.projectId),
          ppmSubscriptions.getSubscriptions(),
          ppmSubscriptions.getProjectSubscriptionPlan(this.projectId),
          getProducts(this.projectId),
          getAccountBalance(this.projectId),
        ]);

        this.project = project;
        this.newDemoStatus = project.info.demoStatus;
        this.paymentPlanGroups = paymentPlanGroups;
        this.projectPaymentPlanGroup = projectPaymentPlan;
        this.products = products;
        this.features = features;
        this.activeFeatures = this.features.filter((x) => x.enabled).map((x) => x.feature);
        this.selectedPpmSubscriptionPlan = subscriptionPlan?.id || '';
        this.existingPpmSubscriptionPlan = subscriptionPlan?.id || '';
        this.ppmSubscriptions = subscriptions.map((s) => ({
          value: s.id,
          // eslint-disable-next-line max-len
          text: `${s.name} - ${s.interval} / ${new Intl.NumberFormat('en-IN', { style: 'currency', currency: s.currency }).format(s.amountInCents / 100)}`,
        }));
        this.refundBaseMultiplier = this.project.info.baseMultiplier;
        this.accountBalance = accountBalanceData.accountBalance.map((ab) => ({
          ...ab,
          amount: Dinero({ amount: ab.amountInCents }).toFormat(),
        }));
        this.canAccountBeCharged = accountBalanceData.canAccountBeCharged;
        this.seats = project.info.seats;

        this.state = 'loaded';
      } catch (error) {
        this.state = 'error';
      }
    },

    showProjectInfoDialog() {
      this.editProjectInfoForm.lnpl = this.project.info.lnpl;
      this.editProjectInfoForm.analytics = this.project.info.analytics;
      this.editProjectInfoDialog = true;
    },

    async editProjectInfo() {
      try {
        this.formState = 'loading';
        await updateAccess(this.$route.params.id, this.editProjectInfoForm);
        await this.getData();
        this.editProjectInfoDialog = false;
        this.formState = '';
      } catch (error) {
        this.formState = 'error';
      }
    },

    showPriceLimitDialog() {
      this.editPriceLimitForm.min = Dinero({ amount: this.project.priceLimits.minPriceInCents }).toUnit();
      this.editPriceLimitForm.max = Dinero({ amount: this.project.priceLimits.maxPriceInCents }).toUnit();
      this.editPriceLimitDialog = true;
    },

    async editPriceLimit() {
      try {
        this.editPriceLimitDialogState = 'loading';
        await updateProjectLimits({
          id: this.$route.params.id,
          min: this.editPriceLimitForm.min,
          max: this.editPriceLimitForm.max,
        });
        this.editPriceLimitDialogState = '';
        this.editPriceLimitDialog = false;
        this.getData();
      } catch (error) {
        this.editPriceLimitDialogState = 'error';
        this.editPriceLimitDialogError = (error as any).data.message || 'Generic Error.';
      }
    },

    getStatusColor(status: string) {
      switch (status) {
      case 'active':
        return 'green';
      case 'deleted':
        return 'red';
      case 'inactive':
        return 'orange';
      default:
        return 'grey';
      }
    },

    dateFormatter(d: string) {
      return new Intl.DateTimeFormat('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      }).format(new Date(d));
    },

    currencyFormatter(amount: number) {
      return Dinero({ amount }).toFormat();
    },

    showCreateVendorDialog() {
      this.openCreateVendorModal = true;
    },

    showUploadLogoDialog() {
      this.openUploadLogoModal = true;
    },
    showUpdateRefundReserveDialog() {
      this.openUpdateRefundReserveDialog = true;
    },

    async submitVendorInfo() {
      this.createVendorForm.phone = this.createVendorForm.phone.replace(/\D/, '');
      if (this.createVendorForm.taxId) {
        this.createVendorForm.taxId.replace(/\D/, '');
      }
      this.createVendorForm.email = this.createVendorForm.vendorPrincipal.email;
      try {
        this.formState = 'loading';
        await createVendorId(this.$route.params.id, this.createVendorForm);
        await this.getData();
        this.formState = 'loaded';
        this.openCreateVendorModal = false;
      } catch (err) {
        this.formState = 'error';
        this.createVendorFormError = (err as any).message || 'Generic Error.';
      }
    },

    handleLogoInputChange(event: Event) {
      const uploadedFile = ((event.target as HTMLInputElement).files as FileList)[0];
      this.logo = uploadedFile;
    },

    async uploadLogo() {
      try {
        this.formState = 'loading';
        await uploadLogo(this.$route.params.id, this.logo);
        await this.getData();
        this.formState = 'loaded';
        this.openUploadLogoModal = false;
      } catch (err) {
        this.formState = 'error';
        this.uploadLogoError = (err as any).message || 'Something went wrong';
      }
    },

    async onPaymentPlanGroupSeleceted(groupId: string) {
      try {
        this.state = 'loading';
        await setProjectPaymentPlanGroup(this.projectId, groupId);
        this.paymentPlanGroups = await getPaymentPlanGroups();
        this.projectPaymentPlanGroup = await getPaymentPlanGroup(this.projectId);
        this.state = 'loaded';
      } catch (error) {
        console.error(error);
        this.state = 'error';
      }
    },

    async setSeats() {
      try {
        this.setSeatsState = 'loading';
        await setProjectSeats({ projectId: this.projectId, seats: this.seats });
        this.setSeatsState = 'loaded';
        this.getData();
      } catch (error) {
        this.setSeatsState = 'error';
        this.setSeatsError = (error as any).data.message;
      }
    },

    async updateDemoStatus() {
      this.updateDemoStatusState = 'loading';
      try {
        await updateDemoStatus(this.projectId, this.newDemoStatus);
      } catch (error) {
        this.updateDemoStatusState = 'error';
        this.updateDemoStatusError = (error as any).data.message;
        return;
      }
      this.updateDemoStatusState = 'loaded';
    },

    async updateProjectFeature() {
      this.updateProjectFeaturesState = 'loading';
      const newFeatures = this.features.map((feat) => (this.activeFeatures.includes(feat.feature)
        ? { ...feat, enabled: true }
        : { ...feat, enabled: false }));
      console.log(newFeatures);
      try {
        await updateFeatures(this.projectId, newFeatures);
        this.updateProjectFeaturesError = '';
      } catch (err) {
        console.log(err);
        this.updateProjectFeaturesState = 'error';
        this.updateProjectFeaturesError = (err as any).data.message;
        return;
      }
      this.updateProjectFeaturesState = 'loaded';
    },

    async createPpmSubscription() {
      try {
        console.log(this.selectedPpmSubscriptionPlan);
        this.createPpmSubscriptionState = 'loading';
        await ppmSubscriptions.createSubscriptions(this.projectId, this.selectedPpmSubscriptionPlan);
        await this.getData();
        this.createPpmSubscriptionState = 'loaded';
      } catch (err) {
        const typedError = err as any;
        console.log(typedError);
        if (typedError.type && typedError.type !== 'GenericError') {
          this.createPpmSubscriptionState = 'error';
          this.createPpmSubscriptionError = typedError.message;
          return;
        }
        this.createPpmSubscriptionState = 'error';
        this.createPpmSubscriptionError = 'Generic Error';
      }
    },

    async updateBaseMultiplier() {
      await updateRefundReserve(this.projectId, this.refundBaseMultiplier);
      this.getData();
      this.openUpdateRefundReserveDialog = false;
    },

    async submitChargeMerchant() {
      try {
        this.chargeMerchantState = 'loading';
        await chargeMerchant(this.projectId, Number(this.chargeAmountInCents), this.chargeCurrency);
        this.showChargeMerchantDialog = false;
        this.chargeMerchantState = 'loaded';
      } catch (err) {
        this.chargeMerchantState = 'error';
        this.chargeMerchantError = (err as any).data.message;
      }
    },
  },
});
